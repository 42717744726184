<template>
  <svg
    width="70px"
    height="70px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    class="p-3"
  >
    <title>link-solid</title>
    <defs>
      <linearGradient id="shapeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color: #ff7e03" />
        <stop offset="100%" style="stop-color: #ff106d" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons_Q2" data-name="icons Q2">
        <path
          d="M13.9,44a10,10,0,0,1-7-2.9,9.8,9.8,0,0,1,0-14l7.2-7.2a9.8,9.8,0,0,1,14,0,2,2,0,0,1-2.8,2.8,6,6,0,0,0-8.4,0L9.7,29.9a5.9,5.9,0,0,0,8.4,8.4l4.5-4.7a2,2,0,0,1,2.8,2.8l-4.5,4.7A10,10,0,0,1,13.9,44Z"
          fill="url(#shapeGradient)"
        />
        <path
          d="M26.9,31a10,10,0,0,1-7-2.9,2,2,0,0,1,2.8-2.8,6,6,0,0,0,8.4,0l7.2-7.2a5.9,5.9,0,0,0-8.4-8.4l-4.5,4.7a2,2,0,0,1-2.8-2.8l4.5-4.7a9.9,9.9,0,0,1,14,14l-7.2,7.2A10,10,0,0,1,26.9,31Z"
          fill="url(#shapeGradient)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "ResourceSVG",
};
</script>
