<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    stroke-width="3"
    fill="none"
  >
    <defs>
      <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color: #ff7e03" />
        <stop offset="100%" style="stop-color: #ff106d" />
      </linearGradient>
    </defs>
    <path
      d="M55.4,32V53.58a1.81,1.81,0,0,1-1.82,1.82H10.42A1.81,1.81,0,0,1,8.6,53.58V10.42A1.81,1.81,0,0,1,10.42,8.6H32"
      stroke="url(#strokeGradient)"
    />
    <polyline
      points="40.32 8.6 55.4 8.6 55.4 24.18"
      stroke="url(#strokeGradient)"
    />
    <line
      x1="19.32"
      y1="45.72"
      x2="54.61"
      y2="8.91"
      stroke="url(#strokeGradient)"
    />
  </svg>
</template>

<script>
export default {
  name: "OpenLinkSVG",
};
</script>

<style scoped></style>
