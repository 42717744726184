<template>
  <div class="sectionLogos flex flex-col items-center k1:p-2 p-0">
    <div
      class="gap-4 h4:gap-6 h6:gap-9 k1:gap-10 flex flex-wrap place-content-center items-center"
    >
      <img
        v-for="logo in logos"
        :key="logo.id"
        class="k1:w-52 w-40 h-fit object-contain"
        v-bind:src="'/placeholders/' + logo"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionLogos",
  components: {},
  setup() {
    let logos = [
      "DLR.png",
      "vdi_tz_logo.svg",
      "Novo_Nordisk_Foundation_logo.svg",
      "QDNL.png",
      "QUIC.svg",
      "EIC.png",
      "QflagFCO.png",
    ];
    return {
      logos,
      Image,
    };
  },
};
</script>

<style scoped></style>
