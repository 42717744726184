<template>
  <svg
      width="70px"
      height="70px"
      viewBox="0 0 24 24"
      fill="none"
      class="pr-2"
      xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
          id="strokeGradient"
          x1="0%"
          y1="100%"
          x2="100%"
          y2="0%"
      >
        <stop offset="0%" style="stop-color: #ff7e03"/>
        <stop offset="100%" style="stop-color: #ff106d"/>
      </linearGradient>
    </defs>
    <path
        d="M7 16L12.25 10.75L15.75 14.25L21 9"
        stroke="url(#strokeGradient)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'AnalysisSVG'
}
</script>