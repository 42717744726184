<template>
  <div class="aboutUs text-justify">
    <h2 class="heading1">
      About Us
      <hr class="gradientLine" />
    </h2>
    <div class="flex flex-col gap-4 mb-12">
      <div>
        The European Quantum Readiness Center (EQRC) is a European Quantum
        Flagship initiative committed to empowering Europe with Quantum
        Readiness. To this end, our goal is to create a quantum-ready society
        and to develop the workforce of tomorrow. To address Europe’s need for a
        strong quantum ecosystem, the EQRC is built on 3 units.
      </div>
      <div class="grid k1:grid-cols-2 grid-cols-1 gap-4 mt-4">
        <div class="flex gap-2 bg-white p-4 rounded-2xl shadow-sm">
          <div class="w-fit border-2 bg-slate-50 rounded-md h-fit">
            <AnalysisSVG />
          </div>
          <div class=" ">
            <span
              class="text-2xl whitespace-nowrap leading-none font-bold gradientText w-fit block"
            >
              I) Analysis
            </span>
            Wherein we are preparing for the impact of Quantum Technology on
            society by analysing the possible futures which will arise resulting
            from it.
            <a
              class="cursor-pointer inline-block relative top-0.5"
              @click="$router.push('/Analysis')"
            >
              <OpenLinkSVG />
            </a>
          </div>
        </div>
        <div>
          <div class="flex gap-2 bg-white p-4 rounded-2xl shadow-sm">
            <div class="w-fit border-2 bg-slate-50 rounded-md h-fit">
              <ResourceSVG />
            </div>
            <div class=" ">
              <span
                class="text-2xl leading-none font-bold gradientText w-fit block"
              >
                II) Resources
              </span>
              Where community-developed and didactically validated modules for
              outreach, education, and training are available for all who wish
              to develop their quantum readiness.
              <a
                class="cursor-pointer inline-block relative top-0.5"
                @click="$router.push('/playlist/page/1')"
              >
                <OpenLinkSVG />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-2 bg-white p-4 rounded-2xl shadow-sm">
        <div class="w-fit border-2 bg-slate-50 rounded-md h-fit">
          <AccordSVG />
        </div>
        <div class="">
          <span
            class="text-2xl leading-none font-bold gradientText w-fit block"
          >
            III) Accord
          </span>
          Commitment to best practices addressing a number of community-driven
          and research-validated principles, each serving as a fundamental
          component of our efforts to drive change and enhance readiness in the
          European Quantum landscape, including both quantum and non-quantum
          organisations. Adoption of the principles will bring about a wide
          range of benefits to the European Quantum landscape, including
          addressing the leaky pipeline, enabling wider outreach and
          inspiration, facilitating standardisation and transparency, and
          providing greater accessibility.
          <a
            class="cursor-pointer inline-block relative top-0.5"
            @click="$router.push('/accords/about')"
          >
            <OpenLinkSVG />
          </a>
        </div>
      </div>
    </div>

    <People />

    <h2 class="heading2 mt-16">EQRC Scholars</h2>
    <div
      class="flex flex-row flex-wrap gap-y-2 place-content-center justify-evenly"
    >
      <div
        class="flex flex-col items-center k1:w-80 w-44 text-center p-2"
        v-for="scholar in EQRCScholars"
        :key="scholar.name"
      >
        <div class="rounded-full overflow-hidden w-24 h-24">
          <img :src="scholar.img" />
        </div>
        <p class="font-medium leading-none k1:text-lg text-base">
          {{ scholar.name }}
        </p>
        <p class="!leading-tight k1:text-sm text-xs text-gray-400">
          {{ scholar.role }}
        </p>
      </div>
    </div>

    <h2 class="heading2 mt-16">Advisory Board</h2>
    <PartnerLogos></PartnerLogos>
  </div>
</template>

<script>
import PartnerLogos from "../PartnerLogos.vue";
import ResourceSVG from "@/views/About/tabs/ResourceSVG.vue";
import AnalysisSVG from "@/views/About/tabs/AnalysisSVG.vue";
import People from "@/components/About/People.vue";
import OpenLinkSVG from "@/views/About/tabs/OpenLinkSVG.vue";
import AccordSVG from "@/views/About/tabs/AccordSVG.vue";

export default {
  name: "AboutUs.vue",
  components: {
    People,
    AnalysisSVG,
    ResourceSVG,
    OpenLinkSVG,
    AccordSVG,
    PartnerLogos,
  },
  setup() {
    const EQRCScholars = [
      {
        name: "Ignacio Godoy-Descazeaux",
        img: "/EQRCScholars/Ignacio.jpg",
        role: "Copenhagen Business School",
      },
      {
        name: "Eleni S. Karydi",
        img: "/EQRCScholars/eleni.jpg",
        role: "Copenhagen University",
      },
      {
        name: "Adam Gordon",
        img: "/EQRCScholars/adam.png",
        role: "Aarhus University",
      },
      {
        name: "Jonas Bley",
        img: "/EQRCScholars/jonas.png",
        role: "Technical University of Kaiserslautern",
      },
      {
        name: "Zeki Can Seskir",
        img: "/EQRCScholars/zeki.jpeg",
        role: "Karlsruhe Institute of Technology",
      },
      {
        name: "Hrvoje Kukina",
        img: "/EQRCScholars/Hrvoje.png",
        role: "TU Wien",
      },
      {
        name: "Stefan Heusler",
        img: "/EQRCScholars/StefanHeusler.jpeg",
        role: "University of Münster",
      },
      {
        name: "Bart Folkers",
        img: "/EQRCScholars/Bart.jpg",
        role: "University of Twente",
      },
      {
        name: "Sohan Vartak",
        img: "/EQRCScholars/Sohan.jpeg",
        role: "Quantum Talent and Learning Centre Amsterdam",
      },
      {
        name: "Philipp Bitzenbauer",
        img: "/EQRCScholars/Philipp.png",
        role: "Friedrich-Alexander-Universität Erlangen-Nürnberg",
      },
    ];
    return { EQRCScholars };
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(45deg, #ff7e03, #ff106d);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
