<template>
  <svg
    class="p-3"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="70px"
    height="70px"
    fill="none"
    viewBox="0 0 24 24"
  >
    <defs>
      <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style="stop-color: #ff7e03" />
        <stop offset="100%" style="stop-color: #ff106d" />
      </linearGradient>
    </defs>
    <path
      stroke="url(#iconGradient)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7.556 8.5h8m-8 3.5H12m7.111-7H4.89a.896.896 0 0 0-.629.256.868.868 0 0 0-.26.619v9.25c0 .232.094.455.26.619A.896.896 0 0 0 4.89 16H9l3 4 3-4h4.111a.896.896 0 0 0 .629-.256.868.868 0 0 0 .26-.619v-9.25a.868.868 0 0 0-.26-.619.896.896 0 0 0-.63-.256Z"
    />
  </svg>
</template>
<script>
export default {
  name: "AccordSVG",
};
</script>
